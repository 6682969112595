import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer className="bg-green-700">
      <div className="max-w-7xl px-4 py-4 mx-auto sm:px-6 lg:px-8">
        <div className="lg:flex">
          <div className="w-full -mx-4 sm:-mx-6 lg:-mx-8 lg:w-2/5">
            <div className="px-4 sm:px-6 lg:px-8">
              <Link className="flex items-center gap-1" to="/">
                <StaticImage
                  src="../img/alphagroup-icon-green-100.jpg"
                  alt="UrbanGarden icon"
                  layout="fixed"
                  width={200}
                  // height={68}
                  backgroundColor="transparent"
                  placeholder="blurred"
                  quality={100}
                />
              </Link>

              <p className="max-w-md mt-2 text-white dark:text-white">
                DHA and CDA Approved builders designing and building worldclass
                modern architecture.
              </p>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
              <div>
                <h3 className="text-white uppercase dark:text-white">About</h3>
                <Link
                  to="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                >
                  Company
                </Link>
                <Link
                  to="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                >
                  Careers
                </Link>
              </div>

              <div>
                <h3 className="text-white uppercase dark:text-white">Blog</h3>
                <Link
                  to="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                >
                  Real estate
                </Link>
                <Link
                  to="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                >
                  Savings and Investments
                </Link>
                <Link
                  to="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                >
                  Videos
                </Link>
              </div>

              <div>
                <h3 className="text-white uppercase dark:text-white">
                  Projects
                </h3>
                <a
                  href="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  Brick One
                </a>
                <a
                  href="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  Brick Two
                </a>
                <a
                  href="/"
                  className="block mt-2 text-sm text-white dark:text-white hover:underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  Brick Three
                </a>
              </div>

              <div>
                <h3 className="text-white uppercase dark:text-white">
                  Contact
                </h3>
                <span className="block mt-2 text-sm text-white dark:text-white hover:underline">
                  +92 51 111 111 666
                </span>
                <span className="block mt-2 text-sm text-white dark:text-white hover:underline">
                  info@alphagroup.com.pk
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

        <div>
          <p className="text-center text-white dark:text-white">
            © Alpha Group 2022 - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
